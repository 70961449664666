//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import StyledLink from "./StyledLink.vue";
export default {
    components: { StyledLink },
    name: "ButtonsTray",
    props: {
        buttons: {
            type: [Array, Object],
            default: null,
        },
        invert: {
            default: null,
        },
        target: {
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions([
            "setMenuState",
            "setMenuData",
            "setFormId",
            "setFormBtn",
        ]),
        formOpen(data, id, btnName) {
            if (data) {
                this.setMenuState(true);
                this.setFormId(id);
                this.setFormBtn(btnName);
                this.setMenuData(data);
            }
        },
    },
};
